<app-header-s></app-header-s>
<section class="suporte">
  <div class="">
    <img src="./assets/img/mobart_logo_semfundo.png" class="logo-mob">
    <h2 class="title">{{ 'support' | translate }} Mobart App</h2>
  </div>
  <div class="sumario">
    <ul>
      <li><a href="suporte#comoBaixar">{{ 'howToGet' | translate }}</a></li>
      <li><a href="suporte#comoUsar">{{ 'howToUse' | translate }}</a></li>
      <ul>
        <li><a href="suporte#iniciando">{{ 'start' | translate }}</a></li>
        <li><a href="suporte#login">{{ 'login' | translate }}</a></li>
        <li><a href="suporte#feedObras">{{ 'artworkFeed' | translate }}</a></li>
        <li><a href="suporte#obraSelecionada">{{ 'selectedArtwork' | translate }}</a></li>
        <li><a href="suporte#realidadeAumentadaComo">{{ 'augmentedReality' | translate }}</a></li>
        <li><a href="suporte#informacoesObra">{{ 'infoArtBuy' | translate }}</a></li>
      </ul>
      <li><a href="suporte#realidadeAumentada">{{ 'augmentedReality' | translate }}</a></li>
      <li><a href="suporte#aparelhosCompativeis">{{ 'compatibleDevices' | translate }}</a></li>
      <li><a href="suporte#outrasDuvidas">{{ 'otherDoubts' | translate }}</a></li>
    </ul>
  </div>

  <div class="como-usar" id="comoBaixar">
    <h2 [innerHTML]="'howToGet'|translate"></h2>
    <p [innerHTML]="'comobaixar-texto'|translate"></p>
    <p [innerHTML]="'ios-link-texto'|translate" class="p-inline"></p>
    <a href="https://apps.apple.com/br/app/mobart-augmented-galleries/id1571749300" target="_blank">
      <p [innerHTML]="'ios-link'|translate" class="p-inline"></p>
    </a>
    <p [innerHTML]="'android-link-texto'|translate" class="p-inline"></p>
    <a href="https://play.google.com/store/apps/details?id=com.mobartapp.mobart " target="_blank">
      <p [innerHTML]="'android-link'|translate" class="p-inline"></p>
    </a>
  </div>


  <div class="como-usar" id="comoUsar">
    <h2 [innerHTML]="'howToUse'|translate"></h2>

    <div class="login" id="iniciando">
      <h3 [innerHTML]="'start'|translate"></h3>
      <p [innerHTML]="'start-text-1'|translate"></p>
      <img src="./assets/img/suporte/001.png" class="img-tutorial">
      <p [innerHTML]="'start-text-2'|translate"></p>
      <img src="./assets/img/suporte/002.png" class="img-tutorial">
      <p [innerHTML]="'start-text-3'|translate"></p>
    </div>


    <div class="login" id="login">
      <h3 [innerHTML]="'login'|translate"></h3>
      <p [innerHTML]="'login1'|translate"></p>
      <img src="./assets/img/suporte/009l.png" class="img-tutorial">
      <p [innerHTML]="'login2'|translate"></p>

    </div>

    <div class="feed" id="feedObras">
      <h3 [innerHTML]="'artworkFeed'|translate"></h3>
      <p [innerHTML]="'artworkFeed1'|translate"></p>
      <p [innerHTML]="'artworkFeed2'|translate"></p>
      <img src="./assets/img/suporte/003.png" class="img-tutorial feed-img">
      <img src="./assets/img/suporte/1012.png" class="img-tutorial feed-img">
    </div>
    <div class="obra" id="obraSelecionada">
      <h3 [innerHTML]="'selectedArtwork'|translate"></h3>
      <p [innerHTML]="'selectedArtwork1'|translate"></p>
      <p [innerHTML]="'selectedArtwork2'|translate"></p>
      <img src="./assets/img/suporte/004.png" class="img-tutorial img-obra">
      <p [innerHTML]="'selectedArtwork3'|translate"></p>
    </div>
    <div class="ar" id="realidadeAumentadaComo">
      <h3 [innerHTML]="'augmentedReality'|translate"></h3>
      <p [innerHTML]="'augmentedReality1'|translate"></p>
      <p [innerHTML]="'augmentedReality2'|translate"></p>
      <ul>
        <li [innerHTML]="'augmentedReality3'|translate"></li>
        <li [innerHTML]="'augmentedReality4'|translate"></li>
      </ul>
      <li [innerHTML]="'augmentedReality44'|translate"></li>
      <img src="./assets/img/suporte/005.png" class="img-tutorial img-ar">

      <p [innerHTML]="'augmentedReality11'|translate"></p>
      <img src="./assets/img/suporte/006.png" class="img-tutorial img-ar ar-img">
      <p [innerHTML]="'augmentedReality10'|translate"></p>
    </div>



    <div class="info-compra" id="informacoesObra">
      <h3 [innerHTML]="'infoArtBuy'|translate"></h3>
      <p [innerHTML]="'infoArtBuy1'|translate"></p>
      <img src="./assets/img/suporte/007.png" class="img-tutorial">
      <p [innerHTML]="'infoArtBuy2'|translate"></p>
      <p [innerHTML]="'infoArtBuy3'|translate"></p>
      <img src="./assets/img/suporte/008m.png" class="img-tutorial">
    </div>
  </div>
  <div class="realidade-aumentada" id="realidadeAumentada">
    <h2 [innerHTML]="'augmentedReality'|translate"></h2>
    <p [innerHTML]="'augmentedReality21'|translate"></p>
    <p [innerHTML]="'augmentedReality22'|translate"></p>
    <p><span [innerHTML]="'augmentedReality23'|translate"></span> <a href="https://drive.google.com/file/d/1Gxl5ll_cBCk4SfkOWccjjJNF7TjeObl9/view" target="_blank"><b [innerHTML]="'augmentedReality24'|translate"></b></a>).</p>
  </div>
  <div class="aparelhos" id="aparelhosCompativeis">
    <h2 [innerHTML]="'compatibleDevices'|translate"></h2>
    <h3>Android</h3>
    <p [innerHTML]="'compatibleDevices1'|translate"></p>
    <h3>iOS</h3>
    <p [innerHTML]="'compatibleDevices2'|translate"></p>
  </div>
  <div class="aparelhos" id="outrasDuvidas">
    <h2 [innerHTML]="'otherDoubts'|translate"></h2>
    <p [innerHTML]="'otherDoubts1'|translate"></p>
  </div>
  <div class="copyright">
    © 2021 | Mobart
  </div>
</section>
