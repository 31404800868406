<section class="saiba-mais">
  <div class="saber-mais-texto">
    <h2 [innerHTML]="'KNOW_MORE'|translate"></h2>
    <p [innerHTML]="'knowMoreComponent1'|translate"></p>
    <a href="/contato" target="_blank" class="botao-tenho-interesse">
      <span [innerHTML]="'knowMoreComponent2'|translate"></span></a>
    <br>
    <a href="https://www.youtube.com/watch?v=MWxTmWuq0XQ&t=2s" target="_blank"><span class="ver-video" [innerHTML]="'knowMoreComponent3'|translate"></span></a>
    <br>
     <a href="https://wa.me/message/HGR3AK2RTPCNA1" target="_blank" class="botao-tenho-interesse">
      <span [innerHTML]="'whatsapp-btn'|translate"></span></a>
  </div>
  <img src="./assets/img/saber-mais.png" class="img-saiba-mais" alt="Ipad com obras de arte">

</section>
<div class="arrow">
  <a href="#footer"><img src="./assets/img/arrow-top.png" class="arrow-realidade-aumentada"></a>
</div>
