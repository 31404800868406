<section class="plataforma">
  <div class="vendas">
    <img src="./assets/img/Vendas.png">
    <h3 [innerHTML]="'platformComponent1'|translate"></h3>
    <h4 [innerHTML]="'platformComponent2'|translate"></h4>
    <p [innerHTML]="'platformComponent3'|translate"></p>

  </div>
  <div class="gestao-acervo">
    <img src="./assets/img/Gestao.png">
    <h3 [innerHTML]="'platformComponent4'|translate"></h3>
    <h4 class="hidden">(e-commerce)</h4>
    <p [innerHTML]="'platformComponent5'|translate"></p>
  </div>
  <div class="realidade-aumentada">
    <img src="./assets/img/Imagem.png">
    <h3 [innerHTML]="'platformComponent6'|translate"></h3>
    <h4 class="hidden-full">(e-commerce)</h4>
    <p [innerHTML]="'platformComponent7'|translate"></p>
  </div>


</section>
<div class="arrow">
  <a href="#oque-paraquem"><img src="./assets/img/arrow-top.png" class="arrow-plataforma" id="oque-paraquem"></a>
</div>