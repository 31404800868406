<section class="baixe-app">
  <img src="./assets/img/baixarapp.png" class="img-baixe-app">

  <div class="baixe-app-texto">
    <h2 [innerHTML]="'downloadComponent1'|translate"></h2>
    <p [innerHTML]="'downloadComponent2'|translate"></p>
    <div class="stores">
      <div class="app-store">
        <a href="https://apps.apple.com/br/app/mobartapp-augmented-galleries/id1624801304" target="_blank">
          <img src="./assets/img/appstore.png" class="img-appstore">
        </a>
        <img src="./assets/img/iosqrcode.png" class="qr-appstore">
      </div>
    </div>
    <div class="stores">
      <div class="playstore">
        <a href="https://play.google.com/store/apps/details?id=com.mobartapp.mobart" target="_blank">
          <img src="./assets/img/playstore.png" class="img-playstore">
        </a>
        <img src="./assets/img/qrplaystore.png" class="qr-playstore">
      </div>
    </div>
  </div>
</section>
<div class="arrow">
  <a href="#saibamais"><img src="./assets/img/arrow-top.png" class="arrow-realidade-aumentada" id="saibamais"></a>
</div>