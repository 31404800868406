<footer id="footer">
  <section class="quem-somos">
    <div class="quem-somos-texto">
      <h2 [innerHTML]="'who'|translate"></h2>
      <p>
        <a href="/equipe" target="_blank"><span class="botao-equipe" [innerHTML]="'footerComponent'|translate"></span></a>
      </p>

      <a href="https://medium.com/@mobartapp" target="_blank" class="botao-blog">
        <span [innerHTML]="'blogButton'|translate"></span></a>

      <div class="footer">
        <img src="./assets/img/mobart_logo_semfundo.png" class="logo-mobart" alt="Logo Mobart">
        <ul>
          <li>contato@mobart.app</li>
          <li>Pulsar Incubadora da UFSM. Av. Roraima 1000</li>
          <li>Santa Maria, Rio Grande do Sul</li>
          <li>Brasil</li>
        </ul>

        <div class="social-medias">
          <a href="https://www.instagram.com/mobart.app/" target="_blank"><img src="./assets/img/inst.png" class="logo-social-media" alt="Logo Instagram"></a>
          <a href="https://www.facebook.com/mobartapp/" target="_blank"><img src="./assets/img/fb.png" class="logo-social-media" alt="Logo Facebook"></a>
        </div>

      </div>
    </div>
    <div class="incubadoras">
      <p [innerHTML]="'businessIncubator'|translate"></p>
      <img src="./assets/img/agittec.png" class="logo-incubadora" alt="Logo Agittec Incubadora">
      <img src="./assets/img/pulsar.png" class="logo-incubadora" alt="Logo Pulsar Incubadora">
      <p class="aceleradoras" [innerHTML]="'businessAccelerator'|translate"></p>
      <img src="./assets/img/wow.png" class="logo-incubadora" alt="Logo WOW Aceleradora">
      <img src="./assets/img/logo_stars_black.png" class="logo-incubadora" alt="Logo Stars Aceleradora">
      <img src="./assets/img/samsung_ocean.png" class="logo-incubadora" alt="Logo Samsung Ocean Aceleradora">
    </div>
  </section>

  <div class="copyright">
    © 2022 | Mobart
    <p>
      <br>
        <a href="/termos" target="_blank"><span [innerHTML]="'footerTermos'|translate"></span></a>
      <br><br>
      </p>
  </div>
</footer>
