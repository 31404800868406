<mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
<app-header-s></app-header-s>
<section id="contato">
  <form [formGroup]="Form" (ngSubmit)="SendContactEmail()">
    <div class="contato">
      <div class="contato-texto">
        <h2>{{ "knowMore" | translate }}</h2>
        <p>
          {{ "contact1" | translate }}
        </p>
      </div>
      <div class="form-group">
        <input
          class="form-control margin-form floating-label-form-group"
          id="ContactName"
          name="ContactName"
          formControlName="name"
          placeholder="{{ 'name' | translate }}"
        />
        <input
          class="form-control margin-form floating-label-form-group"
          id="ContactEmail"
          name="ContactEmail"
          formControlName="email"
          placeholder="E-mail"
        />
        <textarea
          class="form-control"
          placeholder="{{ 'message' | translate }}"
          name="Message"
          id="Message"
          rows="8"
          formControlName="message"
        ></textarea>
      </div>
    </div>
    <div class="footer">
      <div class="social-medias">
        <a href="https://www.instagram.com/mobart.app/" target="_blank"
          ><img
            src="./assets/img/inst.png"
            class="logo-social-media"
            alt="Logo Instagram"
        /></a>
        <a href="https://www.facebook.com/mobartapp/" target="_blank"
          ><img
            src="./assets/img/fb.png"
            class="logo-social-media"
            alt="Logo Facebook"
        /></a>
      </div>
      <button type="submit" class="enviar" id="save" name="save">
        {{ "send" | translate }}
      </button>
    </div>
  </form>
  <div class="copyright">
    <p>© 2021 | Mobart</p>
  </div>
</section>
