import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-realidade-aumentada',
  templateUrl: './realidade-aumentada.component.html',
  styleUrls: ['./realidade-aumentada.component.css']
})
export class RealidadeAumentadaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
