<section class="realidade-aumentada" id="">
  <h2 [innerHTML]="'AUGMENTED_REALITY'|translate"></h2>

  <img src="./assets/img/realidade-aumentada.gif" class="img-realidade-aumentada">

  <p class="text-realidade-aumentada" [innerHTML]="'augmentedRealityComponent'|translate"></p>
</section>
<!-- mobile version -->
<section class="realidade-aumentada-mobile">
  <div class="realidade-aumentada-mob-texto">
    <h2 [innerHTML]="'augmentedReality'|translate"></h2>
    <p class="text-realidade-aumentada" [innerHTML]="'augmentedRealityComponent'|translate"></p>
  </div>
  <img src="./assets/img/realidade-aumentada.gif" class="img-realidade-aumentada">
</section>


<div class="arrow">
  <a href="#plataforma"><img src="./assets/img/arrow-top.png" class="arrow-realidade-aumentada" id="plataforma"></a>
</div>