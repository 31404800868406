import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContatoComponent } from './components/contato/contato.component';
import { RealidadeAumentadaComponent } from './components/realidade-aumentada/realidade-aumentada.component';
import { PlataformaComponent } from './components/plataforma/plataforma.component';
import { ParaQuemComponent } from './components/para-quem/para-quem.component';
import { PlanosComponent } from './components/planos/planos.component';
import { BaixeAppComponent } from './components/baixe-app/baixe-app.component';
import { SaibaMaisComponent } from './components/saiba-mais/saiba-mais.component';
import { FooterComponent } from './components/footer/footer.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { EquipeComponent } from './components/equipe/equipe.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { SuporteComponent } from './components/suporte/suporte.component';
import { TermosComponent } from './components/termos/termos.component';


const routes: Routes = [
  { path: '', component: DashboardComponent },
  { path: 'landing-page', component: LandingPageComponent },
  { path: 'equipe', component: EquipeComponent },
  { path: 'contato', component: ContatoComponent },
  { path: 'realidade', component: RealidadeAumentadaComponent },
  { path: 'plataforma', component: PlataformaComponent },
  { path: 'para-quem', component: ParaQuemComponent },
  { path: 'planos', component: PlanosComponent },
  { path: 'baixe-app', component: BaixeAppComponent },
  { path: 'saiba-mais', component: SaibaMaisComponent },
  { path: 'footer', component: FooterComponent },
  { path: 'suporte', component: SuporteComponent },
  { path: 'termos', component: TermosComponent },
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
