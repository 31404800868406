<header class="language">
  <span class="lang-menu">
    <div class="buttons">
      <button (click)="useLanguage('pt')" class="translate-button button1">PT-BR</button>
      <button (click)="useLanguage('en')" class="translate-button">EN</button>
    </div>
  </span>
</header>

<header>
  <nav class="nav-menu">
    <ul data-menu="list" id="menu" class="left-menu">
      <li> <a href="#landing-page"><img src="./assets/img/mobart_logo_semfundo.png" style="width: 100px;"></a></li>
      <li> <a href="#realidade-aumentada" class="desktop-menu" id="">{{ 'augmentedReality' | translate }}</a></li>
      <li> <a href="#plataforma" class="desktop-menu" id="">{{ 'plataform' | translate }}</a></li>
      <li> <a href="#oque-paraquem" class="desktop-menu" id="">{{ 'whatWhom' | translate }}</a></li>
      <li> <a href="#planos" class="desktop-menu" id="">{{ 'plans' | translate }}</a></li>
      <li> <a href="#baixeapp" class="desktop-menu" id="">{{ 'download' | translate }}</a></li>
      <li> <a href="#saibamais" class="desktop-menu" id="">{{ 'learnMore' | translate }}</a></li>
    </ul>
    <ul class="right-menu">
      <li> <a href="/suporte" target="_blank" id="sup">{{ 'support' | translate }}</a></li>
      <li>
        <a href="https://backoffice.mobartapp.com/" target="_blank" class="button-login">{{ 'login' | translate }}</a>
      </li>
    </ul>
  </nav>
</header>