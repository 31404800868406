<app-header-s></app-header-s>
<section id="equipe">
  <div class="equipe">
    <div class="equipe-texto">
      <h2 class="equipe">{{ 'who' | translate }}</h2>
      <p>{{ 'meetTeam' | translate }}
      </p>
      <ul>
        <li>
          <div class="membro">
            <img src="././assets/img/andrea.jpg" class="rounded-circle mb-2" alt="Foto membro" width="120px" height="120px">
            <p>
              <span class="name">Andrea Capssa - CEO </span><br>
              {{ 'andrea1' | translate }}<br>
              {{ 'andrea2' | translate }}<br>
              {{ 'andrea3' | translate }} <br>
              <span><a href="https://www.linkedin.com/in/andreacapssa/" target="_blank" class="link">LinkedIn</a></span>
            </p>
          </div>
        </li>
        <li>
          <div class="membro">
            <img src="././assets/img/gio.jpg" class="rounded-circle mb-2" alt="Foto membro" width="120px" height="120px">
            <p>
              <span class="name">Giovanna Casimiro - COO </span><br>
              {{ 'giovanna1' | translate }}<br>
              {{ 'giovanna2' | translate }}<br>
              {{ 'giovanna3' | translate }} <br>
              <span><a href="https://www.linkedin.com/in/ggcasimiro/" target="_blank" class="link">LinkedIn</a></span>
            </p>
          </div>
        </li>
        <li>
          <div class="membro">
            <img src="././assets/img/tamara.jpg" class="rounded-circle mb-2" alt="Foto membro" width="120px" height="120px">
            <p>
              <span class="name">Tamara Pereira - CCO </span><br>
              {{ 'tamara1' | translate }}<br>
              {{ 'tamara2' | translate }}<br>
              {{ 'tamara3' | translate }} <br>
              <span><a href="https://www.linkedin.com/in/tamara-lorenzoni-pereira-33432b125/" target="_blank" class="link">LinkedIn</a></span>
            </p>
          </div>
        </li>
        <li>
          <div class="membro">
            <img src="././assets/img/felipe.jpg" class="rounded-circle mb-2" alt="Foto membro" width="120px" height="120px">
            <p>
              <span class="name">Felipe Silveira - CFO</span><br>
              {{ 'felipe1' | translate }}<br>
              {{ 'felipe2' | translate }}<br>
              {{ 'felipe3' | translate }} <br>
              <span><a href="https://www.linkedin.com/in/felipe-lopes-da-silveira/" target="_blank" class="link">LinkedIn</a></span>
            </p>
          </div>
        </li>
        <li>
          <div class="membro">
            <img src="././assets/img/carlos.png" class="rounded-circle mb-2" alt="Foto membro" width="120px" height="120px">
            <p>
              <span class="name">Carlos Donaduzzi - CDO</span><br>
              {{ 'carlos1' | translate }}<br>
              {{ 'carlos2' | translate }}<br>
              {{ 'carlos3' | translate }} <br>
              <span><a href="#" target="_blank" class="link">LinkedIn</a></span>
            </p>
          </div>
        </li>
        <li>
          <div class="membro">
            <img src="././assets/img/sergio.jpg" class="rounded-circle mb-2" alt="Foto membro" width="120px" height="120px">
            <p>
              <span class="name">Sérgio Vieira</span><br>
              {{ 'sergio1' | translate }}<br>
              {{ 'sergio2' | translate }}<br>
              {{ 'sergio3' | translate }} <br>
              <span><a href="https://www.linkedin.com/in/sergioaugustoav/" target="_blank" class="link">LinkedIn</a></span>
            </p>
          </div>
        </li>
        <li>
          <div class="membro">
            <img src="././assets/img/micaela.jpg" class="rounded-circle mb-2" alt="Foto membro" width="120px" height="120px">
            <p>
              <span class="name">Micaela Guimarães - Tech Lead</span><br>
              {{ 'micaela1' | translate }}<br>
              <span><a href="https://www.linkedin.com/in/micaela-guimaraes/" target="_blank" class="link">LinkedIn</a></span>
            </p>
          </div>
        </li>
        <li>
          <div class="membro">
            <img src="././assets/img/gabrieli.jpg" class="rounded-circle mb-2" alt="Foto membro" width="120px" height="120px">
            <p>
              <span class="name">Gabrieli Pavlack</span><br>
              {{ 'gabrieli1' | translate }}
              <br>
              {{ 'gabrieli2' | translate }}
              <br>
              {{ 'gabrieli3' | translate }}
              <br>
              <span><a href="https://www.linkedin.com/in/gabrieli-pavlack-22b5711ab/" target="_blank" class="link">LinkedIn</a></span>
            </p>
          </div>
        </li>
        <li>
          <div class="membro">
            <img src="././assets/img/pedro.jpg" class="rounded-circle mb-2" alt="Foto membro" width="120px" height="120px">
            <p>
              <span class="name">Pedro Casarin</span><br>
              {{ 'pedro1' | translate }}
              <br>
              {{ 'pedro2' | translate }}
              <br>
              <span><a href="https://www.linkedin.com/in/pedro-casarin-838263221/" target="_blank" class="link">LinkedIn</a></span>
            </p>
          </div>
        </li>
        <li>
          <div class="membro">
            <img src="././assets/img/gianluca.jpg" class="rounded-circle mb-2" alt="Foto membro" width="120px" height="120px">
            <p>
              <span class="name">Gianluca Perez</span><br>
              {{ 'gianluca1' | translate }}
              <br>
              {{ 'gianluca2' | translate }}
              <br>
              <span><a href="https://www.linkedin.com/in/gianluca-perez-dal-zuffo-9556b7158/" target="_blank" class="link">LinkedIn</a></span>
            </p>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div class="footer">
    <div class="social-medias">
      <a href="https://www.instagram.com/mobart.app/" target="_blank"><img src="./assets/img/inst.png" class="logo-social-media" alt="Logo Instagram"></a>
      <a href="https://www.facebook.com/mobartapp/" target="_blank"><img src="./assets/img/fb.png" class="logo-social-media" alt="Logo Facebook"></a>
    </div>
    <div class="copyright">
      © 2021 | Mobart
    </div>
  </div>


</section>