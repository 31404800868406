import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-planos',
  templateUrl: './planos.component.html',
  styleUrls: ['./planos.component.css'],
})
export class PlanosComponent implements OnInit {
  constructor(private router: Router) { }

  ngOnInit(): void { }

  StartToPlan(idPlan: any) {
    this.router.navigate(['/']).then((result) => {
      console.log(result);
      window.open(
        `https://backoffice.mobartapp.com/sing-in/start-to-plan?startplan=true&showmodal=true&origin=${window.origin}`,
        '_blank'
      );
    });
  }
}
