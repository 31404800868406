<!-- secondary header-->

<header>
  <nav class="nav-menu">
    <a href="#landing-page" target="_blank" class="mobartLogo"><img src="./assets/img/mobart_logo_semfundo.png" style="width: 100px;"></a>
    <div class="buttons">
      <button (click)="useLanguage('pt')" class="translate-button button1">PT-BR</button>
      <button (click)="useLanguage('en')" class="translate-button">EN</button>
    </div>
  </nav>
</header>
