import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ContactService } from 'src/app/services/contact.service';

@Component({
  selector: 'app-contato',
  templateUrl: './contato.component.html',
  styleUrls: ['./contato.component.css'],
})
export class ContatoComponent implements OnInit {
  loading: boolean;
  constructor(
    private _form: FormBuilder,
    private contactService: ContactService,
    private alert: ToastrService
  ) {}

  ngOnInit(): void {
  }

  Form = this._form.group({
    name: ['', Validators.required],
    email: ['', Validators.required],
    message: ['', Validators.required],
    originUrl: ['', Validators.required],
  });

  SendContactEmail() {
    this.loading = true;
    this.Form.patchValue({
      originUrl: window.origin + window.location.pathname,
    });
    this.contactService.SendContactEmail(this.Form.value).subscribe(
      (res) => {
        this.Form.reset();
        this.alert.success(
          'Sua mensagem já foi enviada a equipe da Mobartapp, responderemos assim que possível. Obrigado pelo seu contato! :)'
        );
        this.loading = false;
      },
      (erro) => {
        console.log('err', erro);
        this.loading = false;
        this.alert.error(
          'Não foi possível enviar a sua mensagem no momento, tente novamente mais tarde!',
          erro
        );
      }
    );
  }
}
