<section class="landing-page" id="landing-page">
  <div class="logo-container">
    <img src="./assets/img/mobart_logo_semfundo.png" class="logo-mobart">
    <p class="slogan" [innerHTML]="'landingPageComponent1'|translate"></p>
    <a href="#realidade-aumentada" class="call-to-action" [innerHTML]="'landingPageComponent2'|translate"></a>
  </div>
  <img src="./assets/img/Mobart_landing_page.gif" class="img-landing-page">

  <div class="logo-container-mobile">
    <img src="./assets/img/mobart_logo_semfundo.png" class="logo-mobart">
    <p class="slogan" [innerHTML]="'landingPageComponent1'|translate"></p>
    <a href="#realidade" class="call-to-action" [innerHTML]="'landingPageComponent2'|translate"></a>
  </div>
  <img src="./assets/img/Mobart_site_home.png" class="img-landing-page-mobile">
</section>
<div class="arrow">
  <a href="#realidade-aumentada"><img src="./assets/img/arrow-top.png" class="arrow-landing" id="realidade-aumentada"></a>
</div>