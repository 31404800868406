import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ContactService {
  url = environment.API;

  MethodsExternal = 'MethodsExternal/';

  constructor(private http: HttpClient) {}

  SendContactEmail(data: Object): Observable<Object> {
    return this.http.post(
      this.url + this.MethodsExternal + 'SendEmailAlertContactWebsite',
      data
    );
  }
}
