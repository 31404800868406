<section class="para-quem">
  <h2 [innerHTML]="'forWhomComponent1'|translate"></h2>

  <div class="para-quem-texto">
    <p [innerHTML]="'forWhomComponent2'|translate"></p>
    <p [innerHTML]="'forWhomComponent3'|translate"></p>
    <p [innerHTML]="'forWhomComponent4'|translate"></p>
  </div>
  <img src="./assets/img/mob1.gif" class="img-para-quem">
</section>
<div class="arrow">
  <a href="#planos"><img src="./assets/img/arrow-top.png" class="arrow-realidade-aumentada" id="planos"></a>
</div>