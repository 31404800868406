import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { RealidadeAumentadaComponent } from './components/realidade-aumentada/realidade-aumentada.component';
import { PlataformaComponent } from './components/plataforma/plataforma.component';
import { ParaQuemComponent } from './components/para-quem/para-quem.component';
import { PlanosComponent } from './components/planos/planos.component';
import { BaixeAppComponent } from './components/baixe-app/baixe-app.component';
import { SaibaMaisComponent } from './components/saiba-mais/saiba-mais.component';
import { AppRoutingModule } from './app-routing.module';
import { ContatoComponent } from './components/contato/contato.component';
import { EquipeComponent } from './components/equipe/equipe.component';
import { SuporteComponent } from './components/suporte/suporte.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { HeaderSComponent } from './header-s/header-s.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TermosComponent } from './components/termos/termos.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LandingPageComponent,
    RealidadeAumentadaComponent,
    PlataformaComponent,
    ParaQuemComponent,
    PlanosComponent,
    BaixeAppComponent,
    SaibaMaisComponent,
    ContatoComponent,
    EquipeComponent,
    SuporteComponent,
    DashboardComponent,
    HeaderSComponent,
    TermosComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    MatProgressBarModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ToastrModule.forRoot({
      closeButton: true,
      timeOut: 5000,
      tapToDismiss: false,
      preventDuplicates: true,
      positionClass: 'toast-bottom-right',
    }),
    BrowserAnimationsModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
