<app-header-s></app-header-s>

<h2 [innerHTML]="'useTerms'|translate"></h2>

<p [innerHTML]="'terms1'|translate"></p>
<p [innerHTML]="'terms2'|translate"></p>

<h3 [innerHTML]="'general'|translate"></h3>
<p [innerHTML]="'general1'|translate"></p>
<p [innerHTML]="'general2'|translate"></p>
<p [innerHTML]="'general3'|translate"></p>
<p [innerHTML]="'general4'|translate"></p>

<h3 [innerHTML]="'objective'|translate"></h3>
<p [innerHTML]="'objective1'|translate"></p>
<p [innerHTML]="'objective2'|translate"></p>
<p [innerHTML]="'objective3'|translate"></p>
<p [innerHTML]="'objective4'|translate"></p>
<p [innerHTML]="'objective5'|translate"></p>
<p [innerHTML]="'objective6'|translate"></p>
<p [innerHTML]="'objective7'|translate"></p>
<p [innerHTML]="'objective8'|translate"></p>
<p [innerHTML]="'objective9'|translate"></p>
<p [innerHTML]="'objective10'|translate"></p>

<h3 [innerHTML]="'register'|translate">3. Do Cadastro</h3>
<p [innerHTML]="'register1'|translate"></p>
<p [innerHTML]="'register2'|translate"></p>
<p [innerHTML]="'register3'|translate"></p>
<p [innerHTML]="'register4'|translate"></p>
<p [innerHTML]="'register5'|translate"></p>
<p [innerHTML]="'register6'|translate"></p>

<h3 [innerHTML]="'license'|translate"></h3>
<p [innerHTML]="'license1'|translate"></p>
<p [innerHTML]="'license2'|translate"></p>
<p [innerHTML]="'license2a'|translate"></p>
<p [innerHTML]="'license2b'|translate"></p>
<p [innerHTML]="'license2c'|translate"></p>
<p [innerHTML]="'license2d'|translate"></p>

<h3 [innerHTML]="'responsibilities'|translate"></h3>
<p [innerHTML]="'responsibilities1'|translate">
</p>
<p [innerHTML]="'responsibilities2'|translate"></p>
<p [innerHTML]="'responsibilities3'|translate"></p>
<p [innerHTML]="'responsibilities4'|translate"></p>
<p [innerHTML]="'responsibilities5'|translate"></p>
<p [innerHTML]="'responsibilities6'|translate"></p>
<p [innerHTML]="'responsibilities7'|translate"></p>
<p [innerHTML]="'responsibilities8'|translate"></p>
<p [innerHTML]="'responsibilities9'|translate"></p>
<p [innerHTML]="'responsibilities10'|translate"></p>

<h3 [innerHTML]="'final'|translate"></h3>
<p [innerHTML]="'final1'|translate"></p>
<p [innerHTML]="'final2'|translate"></p>
<p [innerHTML]="'final3'|translate"></p>
<p [innerHTML]="'final4'|translate"></p>

<br><br><br>
<h2 [innerHTML]="'privacy'|translate"></h2>
<p [innerHTML]="'privacy1'|translate"></p>

<h3 [innerHTML]="'acquisition'|translate"></h3>
<p [innerHTML]="'acquisition1'|translate"></p>
<p [innerHTML]="'acquisition2'|translate"></p>
<p [innerHTML]="'acquisition3'|translate"></p>
<p [innerHTML]="'acquisition4'|translate"></p>
<p [innerHTML]="'acquisition5'|translate"></p>

<h2 [innerHTML]="'confidentiality'|translate"></h2>
<p [innerHTML]="'confidentiality1'|translate"></p>
<p [innerHTML]="'confidentiality2'|translate"></p>

<h3 [innerHTML]="'doubts'|translate"></h3>
<p [innerHTML]="'doubts1'|translate"></p>

<div class="copyright">
    © 2021 | Mobart
</div>