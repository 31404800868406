<!-- Container (Pricing Section) -->
<link rel="preconnect" href="https://fonts.googleapis.com" />
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
<link href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,900;1,300&display=swap"
  rel="stylesheet" />

<div id="pricing" class="container-fluid">
  <div class="text-center">
    <h2 [innerHTML]="'PLANS' | translate"></h2>
    <h4 [innerHTML]="'availableGA' | translate"></h4>
  </div>
  <div class="row slideanim">
    <div class="col-md-3 col-sm-2 col-xs-12 plan-small-desktop">
      <div class="panel panel-default text-center equal-height">
        <div class="panel-heading">
          <h1 [innerHTML]="'basic' | translate"></h1>
        </div>
        <div class="panel-body">
          <div class="body1">
            <h3>R$9,99</h3>
            <h4 [innerHTML]="'basicPrice' | translate"></h4>
          </div>
          <img src="./assets/icon_planos.png" width="15px" />
          <p>
            <span [innerHTML]="'basic1' | translate"></span>
          </p>
          <img src="./assets/icon_planos.png" width="15px" />
          <p [innerHTML]="'basic2' | translate"></p>
          <img src="./assets/icon_planos.png" width="15px" />
          <p [innerHTML]="'basic3' | translate"></p>
          <img src="./assets/icon_planos.png" width="15px" />
          <p [innerHTML]="'basic4' | translate"></p>
          <img src="./assets/icon_planos.png" width="15px" />
          <p [innerHTML]="'basic5' | translate"></p>
        </div>
        <div class="panel-footer">
          <button class="btn-trial" style="color: black" [innerHTML]="'basic6' | translate"></button>
          <br />
          <button class="btn btn-lg" [innerHTML]="'begin' | translate" (click)="StartToPlan(1)"></button>
        </div>
      </div>
    </div>
    <div class="col-md-3 col-sm-2 col-xs-12 plan-small-mobile">
      <div class="panel panel-default text-center equal-height">
        <div class="panel-heading">
          <h1 [innerHTML]="'premium' | translate"></h1>
        </div>
        <div class="panel-body">
          <div class="body1">
            <h3>R$49,99</h3>
            <h4 [innerHTML]="'premiumPrice' | translate"></h4>
          </div>
          <img src="./assets/icon_planos.png" width="15px" />
          <p [innerHTML]="'premium1' | translate"></p>
          <img src="./assets/icon_planos.png" width="15px" />
          <p [innerHTML]="'basic2' | translate"></p>
          <img src="./assets/icon_planos.png" width="15px" />
          <p [innerHTML]="'basic3' | translate"></p>
          <img src="./assets/icon_planos.png" width="15px" />
          <p [innerHTML]="'basic4' | translate"></p>
          <img src="./assets/icon_planos.png" width="15px" />
          <p [innerHTML]="'basic5' | translate"></p>
          <img src="./assets/icon_planos.png" width="15px" />
          <p [innerHTML]="'premium2' | translate"></p>
        </div>
        <div class="panel-footer">
          <button class="btn btn-lg" [innerHTML]="'begin' | translate" (click)="StartToPlan(1)"></button>
        </div>
      </div>
    </div>
    <div class=" col-md-3 col-sm-2 col-xs-12 plan-small-desktop">
      <div class="panel panel-default text-center equal-height">
        <div class="panel-heading">
          <h1 [innerHTML]="'full' | translate"></h1>
        </div>
        <div class="panel-body">
          <div class="body1">
            <h3>R$299,90</h3>
            <h4 [innerHTML]="'fullPrice' | translate"></h4>
          </div>
          <ul>
            <li>
              <img src="./assets/icon_planos.png" width="15px" />
              <p [innerHTML]="'full1' | translate"></p>
            </li>

            <li>
              <img src="./assets/icon_planos.png" width="15px" />
              <p [innerHTML]="'basic2' | translate"></p>
            </li>
            <li>
              <img src="./assets/icon_planos.png" width="15px" />
              <p [innerHTML]="'basic3' | translate"></p>
            </li>
            <li>
              <img src="./assets/icon_planos.png" width="15px" />
              <p [innerHTML]="'basic4' | translate"></p>
            </li>
            <li>
              <img src="./assets/icon_planos.png" width="15px" />
              <p [innerHTML]="'basic5' | translate"></p>
            </li>
            <li>
              <img src="./assets/icon_planos.png" width="15px" />
              <p [innerHTML]="'full2' | translate"></p>
            </li>
            <li>
              <img src="./assets/icon_planos.png" width="15px" />
              <p [innerHTML]="'full3' | translate"></p>
            </li>
          </ul>
        </div>
        <div class="panel-footer">
          <button class="btn btn-lg" [innerHTML]="'begin' | translate" (click)="StartToPlan(1)"></button>
        </div>
      </div>
    </div>
    <div class=" col-md-3 col-sm-2 col-xs-12 plan-small-mobile">
      <div class="panel panel-default text-center equal-height">
        <div class="panel-heading">
          <h1 [innerHTML]="'onDemand' | translate"></h1>
        </div>
        <div class="panel-body">
          <div class="body1">
            <h3 class="adicionais" [innerHTML]="'onDemand1' | translate"></h3>
          </div>
          <img src="./assets/icon_planos.png" width="15px" />
          <p [innerHTML]="'onDemand2' | translate"></p>
          <img src="./assets/icon_planos.png" width="15px" />
          <p [innerHTML]="'onDemand3' | translate"></p>
          <img src="./assets/icon_planos.png" width="15px" />
          <p [innerHTML]="'onDemand4' | translate"></p>
          <img src="./assets/icon_planos.png" width="15px" />
          <p [innerHTML]="'onDemand5' | translate"></p>
          <img src="./assets/icon_planos.png" width="15px" />
          <p [innerHTML]="'onDemand6' | translate"></p>
          <img src="./assets/icon_planos.png" width="15px" />
          <p [innerHTML]="'onDemand7' | translate"></p>
        </div>
        <div class="panel-footer">
          <button class="btn btn-lg" [innerHTML]="'begin' | translate" (click)="StartToPlan(1)"></button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="arrow">
  <a href="#baixeapp"><img src="./assets/img/arrow-top.png" class="arrow-plataforma" id="baixeapp" /></a>
</div>