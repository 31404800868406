import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-plataforma',
  templateUrl: './plataforma.component.html',
  styleUrls: ['./plataforma.component.css']
})
export class PlataformaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
