import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-header-s',
  templateUrl: './header-s.component.html',
  styleUrls: ['./header-s.component.css']
})
export class HeaderSComponent implements OnInit {

  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
  }
  useLanguage(language: string): void {
    this.translate.use(language);
  }
}
