<app-header></app-header>
<app-landing-page></app-landing-page>
<app-realidade-aumentada></app-realidade-aumentada>
<app-plataforma></app-plataforma>
<app-para-quem></app-para-quem>
<app-planos></app-planos>
<app-baixe-app></app-baixe-app>
<app-saiba-mais></app-saiba-mais>
<app-footer></app-footer>
<!-- <app-contato></app-contato> -->
